/* eslint-disable no-console */
import {
    addUnAuthenticatedUserId,
    removeUnAuthenticatedUserId,
    store,
    ThriveApplication
} from '@thriveglobal/thrive-web-core'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import ThemedTeamsAuth from './Providers/ThemedTeamsAuth'
import { getOrGenerateUserId } from './utils/getTempUserId'

function loadi18n(lang: string) {
    return fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)
}

export default function Root(props) {
    useEffect(() => {
        const userId = getOrGenerateUserId()
        addUnAuthenticatedUserId(userId)
        return () => {
            removeUnAuthenticatedUserId()
        }
    }, [])

    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            apolloClientOptions={{
                name: 'thrive-web-ms-teams-auth',
                cacheOptions: {}
            }}
        >
            <Provider store={store}>
                <section>
                    <ThemedTeamsAuth />
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
