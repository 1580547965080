import {
    captureMessage,
    captureException,
    isFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import {
    auth,
    SigninResult,
    SigninResultType
} from '@thriveglobal/thrive-web-auth-core'
import { app } from '@microsoft/teams-js'
import { getParamRelay } from './getParamRelay'

export async function signIntoThrive(
    context: app.Context,
    token: string
): Promise<SigninResult> {
    const deepLinkPath = context.page?.subPageId
    const hasNewMsTeamsTokenExchangeFlow = await isFeatureEnabled(
        'newMsTeamsTokenExchangeFlow',
        false,
        true,
        { tenantId: context?.user?.tenant?.id }
    )
    if (deepLinkPath) {
        captureMessage('capture deep link path', {
            category: 'auth',
            data: deepLinkPath
        })
    }

    const relayPath = deepLinkPath ? deepLinkPath : getParamRelay()
    const azureToken = hasNewMsTeamsTokenExchangeFlow ? null : token
    auth.configureAzure({
        azureToken: azureToken,
        azureRelayPath: `/${relayPath}`
    })
    if (hasNewMsTeamsTokenExchangeFlow) {
        const tokenExchangeResult = await auth.tokenExchange(token, 'azure')
        if (typeof tokenExchangeResult === 'undefined') {
            captureException(new Error('tokenExchange failed'), {
                msg: 'tokenExchange failed, the return value is undefined'
            })
            return {
                resultType: SigninResultType.failed,
                reason: 'tokenExchange failed'
            }
        }
        return tokenExchangeResult
    }
    return auth.signIn()
}
